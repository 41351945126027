import React, { useState } from 'react';
import HeaderComponet from '../ReusableComponents/HeaderComponent';
import re1 from '../AssetsComponents/re1.png';
import re2 from '../AssetsComponents/logo.png';
import '../StyleComponents/UserOtpAuthentication.css'
import { useNavigate } from 'react-router-dom';
import sss from '../AssetsComponents/sss.png'; // Your image for the popup


function UserOtpAuthentication() {
    const navigate = useNavigate(); // Initialize useNavigate

    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State for controlling the popup

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        // Set the OTP state
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Move to the next input field
        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };

    const handleResend = () => {
        setIsResendDisabled(true);
        // Add your resend OTP logic here (e.g., API call to resend OTP)
        setTimeout(() => setIsResendDisabled(false), 30000); // Enable resend after 30 seconds
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        // Perform your form validation or API call here for OTP verification
        
        // Open the popup after successful submission
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
        // After closing the popup, navigate to the login page
        navigate('/HomeComponent');
    };
    return (
        <>
            <HeaderComponet />
            <div className="container">
                <div className="curved-box">
                    <img src={re1} alt="Background" className="curved-image" />
                    <img src={re2} alt="Logo" className="center-logo" />
                </div>
                <div className="signin-container">
                    <h2 className="signin-header">OTP Verification</h2>
                    <p style={{marginLeft:'30%',marginTop:'-20px',color:'GrayText'}}>Sevizo sent you a one-time verification code on this number.</p>
                    <form className="signin-form" onSubmit={handleSubmit}> {/* Attach handleSubmit to the form */}
                        <div className="form-group">
                            <label htmlFor="mobile" className='mobile1'>Enter code</label>
                            <div className="otp-container">
                                {otp.map((data, index) => {
                                    return (
                                        <input
                                            type="text"
                                            name="otp"
                                            maxLength="1"
                                            key={index}
                                            value={data}
                                            className={`otp-input ${data ? 'filled' : ''}`}  // Add a 'filled' class when data is entered
                                            onChange={(e) => handleChange(e.target, index)}
                                            onFocus={(e) => e.target.select()}  // Automatically select text on focus
                                        />
                                    );
                                })}
                            </div>
                            <h3 className="resend-btn" onClick={handleResend} disabled={isResendDisabled}>
                                Resend Code
                            </h3>
                        </div>
                        <button type="submit" className="continue-btn1">Verify & Process</button>
                    </form>
                </div>
            </div>

            {/* Popup */}
            {isPopupOpen && (
                <div className="popup-overlay">

                    <div className="popup-content">
                    <p style={{fontWeight:'700',fontSize:'32px'}}>Verified </p>

                    <p>Your account has been verified, please login to continue.</p>

                        <img src={sss} alt="Success" className="popup-image" /> {/* Popup image */}
                        <button onClick={handlePopupClose} className="popup-ok-btn">Login</button> {/* OK button */}
                    </div>
                </div>
            )}
        </>
    )
}

export default UserOtpAuthentication