import React from 'react';
import '../StyleComponents/UserFooter.css'
import logo1 from '../AssetsComponents/logo.png'
import apple from '../AssetsComponents/apple.png'
import Play from '../AssetsComponents/play.png'
import facebook from '../AssetsComponents/facebook.png'
import instagram from '../AssetsComponents/instagram.png'
import victor from '../AssetsComponents/Vector.png'




const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <nav className="footer-nav">
          <a href="#">About</a>
          <a href="#">Privacy Policy</a>
          <a href="#">Terms & Conditions</a>
          <a href="#">Contact</a>
        </nav>
        <div className="app-links">
          <img src={apple} alt="Download from App Store" />
          <img src={Play} alt="Download from Google Play" />
        </div>
      </div>

      <div className="footer-bottom">
        <img src={logo1} alt="Sevizo Logo" className="footer-logo" />
        <p>© Sevizo 2024 | All Rights Reserved</p>
        <div className="social-icons">
        <img src={victor} alt="Twiter" />
        <img src={facebook} alt="facebook" />
        <img src={instagram} alt="instgram" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
