import React from 'react'
import UserHomeComponent from './UserHomeComponent'
import '../StyleComponents/HomeComponent.css'
import l1 from '../AssetsComponents/home.png'
import rect4 from '../AssetsComponents/Rect4.png'
import rect5 from '../AssetsComponents/Rect5.png'
import { FaHome, FaCar, FaDog, FaStar, FaMapMarkerAlt } from 'react-icons/fa';
import { GiLipstick } from 'react-icons/gi';
import { MdLocalHospital, MdLocalCarWash } from 'react-icons/md';
import g1 from '../AssetsComponents/Group73.png'
import g6 from '../AssetsComponents/Group73.png'
import g2 from '../AssetsComponents/Group74.png'
import g3 from '../AssetsComponents/Group 75.png'
import g4 from '../AssetsComponents/Group76.png'
import g5 from '../AssetsComponents/Group77.png'
import UserFooter from '../ReusableComponents/UserFooter'




const ServiceProviderCard = ({ image, price, rating, name, location, service }) => (
    <div className="service-provider-card">
        <img src={image} alt={service} className="provider-image" />
        <div className="provider-info">
            <div className="price-rating">
                <span className="price">${price}</span>
                <span className="rating">
                    <FaStar className="star-icon" /> {rating} Reviews
                </span>
            </div>
            <div className="provider-name">
                <img src={rect4} alt={name} className="avatar" />
                <span style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: 'bold' }}>{name}</span>
            </div>
            <div className="location">
                <FaMapMarkerAlt className="location-icon" />
                <span>{location}</span>
            </div>
            <div className="service-type">{service}</div>
        </div>
    </div>
);
function HomeComponent() {
    const cardData = [
        {
            title: 'House Cleaning',
            reviews: '4.5 Reviews',
            location: 'Chicago, USA',
            description: 'Lorem ipsum is simply dummy',
            image: rect4,
        },
        {
            title: 'Beauty',
            reviews: '4.5 Reviews',
            location: 'Chicago, USA',
            description: 'Lorem ipsum is simply dummy ',
            image: rect5,
        },
        {
            title: 'Beauty',
            reviews: '4.5 Reviews',
            location: 'Chicago, USA',
            description: 'Lorem ipsum is simply dummy',
            image: rect5,
        },
        {
            title: 'Beauty',
            reviews: '4.5 Reviews',
            location: 'Chicago, USA',
            description: 'Lorem ipsum is simply dummy',
            image: rect5,
        },
        // Add more card data...
    ];


    const providers = [
        {
            image: rect5,
            price: 30,
            rating: 4.5,
            name: "Sajib Ahmed",
            location: "Chicago, USA",
            service: "Home Cleaning"
        },
        {
            image: rect5,
            price: 30,
            rating: 4.5,
            name: "Sajib Ahmed",
            location: "Chicago, USA",
            service: "Home Cleaning"
        },
        {
            image: rect5,
            price: 30,
            rating: 4.5,
            name: "Sajib Ahmed",
            location: "Chicago, USA",
            service: "Home Cleaning"
        },
        {
            image: rect5,
            price: 30,
            rating: 4.5,
            name: "Sajib Ahmed",
            location: "Chicago, USA",
            service: "Home Cleaning"
        },
        // Duplicate the above object 3 more times to have 4 identical cards
    ];
    return (
        <>
            <UserHomeComponent />
            <div className='bnr1'>
                <h1 style={{ color: 'white', fontWeight: 700, fontSize: '38px' }}>
                    Explore On-Demand Service's Now!
                </h1>
                <div className='card3'>
                    <div className='box1'>
                        <img src={l1} alt='logo' />
                        <span>Home</span>
                    </div>
                    <div className='box1'>
                        <img src={l1} alt='logo' />
                        <span>Beauty</span>
                    </div>
                    <div className='box1'>
                        <img src={l1} alt='logo' />

                        <span>Medical</span>
                    </div>
                    <div className='box1'>
                        <img src={l1} alt='logo' />

                        <span>Auto</span>
                    </div>

                </div>
                <div className='card3'>
                    <div className='box1'>
                        <img src={l1} alt='logo' />

                        <span>Home</span>
                    </div>
                    <div className='box1'>
                        <img src={l1} alt='logo' />

                        <span>Beauty</span>
                    </div>
                    <div className='box1'>
                        <img src={l1} alt='logo' />

                        <span>Medical</span>
                    </div>
                    <div className='box1'>
                        <img src={l1} alt='logo' />

                        <span>Auto</span>
                    </div>

                </div>
            </div>
            <h3 style={{ marginLeft: '40px', marginTop: '40px' }}>Recently Viewed</h3>

            <div className="slider-container">
                {cardData.map((card, index) => (
                    <div key={index} className="card6">
                        <img src={card.image} alt={card.title} className="card-image" />
                        <div className="card-content">
                            <h4>{card.title}</h4>
                            <p style={{fontWeight:'500'}}>
                                <FaStar style={{ marginRight: '5px', color: '#ffa000' }} />
                                {card.reviews}
                            </p>
                            <p style={{fontWeight:'700'}}>
                                <FaMapMarkerAlt style={{ marginRight: '5px', color: 'rgba(0, 0, 0, 1)' }} />
                                {card.location}
                            </p>
                            <p className="description">{card.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="header9">
                <div className='our'>
                    <h2 style={{ marginLeft: '60px' }}>Our Services</h2>
                    <a href="#" className="see-all">See all</a>
                </div>

            </div>
            <div className="image-row1">
                <img src={g1} alt="Service 1" />
                <img src={g6} alt="Service 2" />
                <img src={g2} alt="Service 3" />
                <img src={g3} alt="Service 4" />
                <img src={g4} alt="Service 5" />
                <img src={g5} alt="Service 6" />
            </div>
            <div className="popular-service-providers">
                <h2 style={{ marginLeft: '40px' }}>Popular service providers</h2>
                <div className="providers-grid">
                    {providers.map((provider, index) => (
                        <ServiceProviderCard key={index} {...provider} />
                    ))}
                </div>
            </div>
            <UserFooter />

        </>
    )
}

export default HomeComponent