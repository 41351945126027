import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import the useNavigate hook
import HeaderComponet from '../ReusableComponents/HeaderComponent';
import '../StyleComponents/UserSignupComponent.css';
import re1 from '../AssetsComponents/re1.png';
import re2 from '../AssetsComponents/logo.png';
import google from '../AssetsComponents/google.png';
import face from '../AssetsComponents/Face.png';
import im290 from '../AssetsComponents/im290.png';
function UserSignupComponent() {
  const navigate = useNavigate();  // Create the navigate function

  const handleSubmit = (event) => {
    event.preventDefault();  // Prevent form submission refresh
    // Add validation logic here if needed

    // Navigate to the desired page
    navigate('/UserSignupAuthentication');  // Replace '/home' with the path you want to navigate to
  };
  return (
    <>
      <HeaderComponet />
      <div className="container">
        <div className="curved-box">
          <img src={re1} alt="Background" className="curved-image" />
          <img src={re2} alt="Logo" className="center-logo" />
        </div>
        <div className="signin-container">
          <h2 className="signin-header">Create account</h2>
          <form className="signin-form" onSubmit={handleSubmit}> {/* Attach handleSubmit to the form */}
            <div className="form-group">
              <label htmlFor="mobile" className='mobile2'> First Name</label>
              <div className="input-group">
                <input type="name" id="mobile" placeholder="" />

              </div>

            </div>
            <div className="form-group">
              <label htmlFor="mobile" className='mobile2'>Last Name </label>
              <div className="input-group">
                <input type="last" id="mobile" placeholder="" />

              </div>

            </div>
            <div className="form-group">
              <label htmlFor="mobile" className='mobile2'>Email </label>
              <div className="input-group">
                <input type="email" id="mobile" placeholder="" />

              </div>

            </div>
            <div className="form-group">
              <label htmlFor="mobile" className='mobile2'>Mobile number</label>
              <div className="input-group">
                <div className="country-code">
                  <span>+40</span>
                  <img src={im290} alt="Flag" className="flag" />
                </div>
                <input type="tel" id="mobile" placeholder="xxx-xxx-xxxx" />
              </div>
            </div>
            <div className="terms-container">
              <input type="checkbox" id="terms-checkbox" className="checkbox" />
              <label htmlFor="terms-checkbox" className="custom-checkbox">
                <span className="checkmark"></span>
                I agree to the Terms and Conditions
              </label>
            </div>
            <button type="submit" className="continue-btn">Continue</button> {/* Submit button */}

          </form>
        </div>
      </div>

      <p style={{ marginTop: "290px", marginLeft: '44%' }}>
        Don’t have an account?
        <a href="/LoginComponrnt" className="signup-btn">Signin</a>
      </p>
    </>)
}

export default UserSignupComponent