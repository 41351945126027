import React from 'react';
import { FaSearch, FaMapMarkerAlt, FaCalendarAlt, FaClock, FaLanguage } from 'react-icons/fa';
import '../StyleComponents/HeaderComponent.css';
import logo from '../AssetsComponents/header.png'
import im290 from '../AssetsComponents/im290.png';


const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="search-bar">
        <FaSearch className="search-icon" />
        <input type="text" placeholder="Search for service" />
      </div>
      <div className="location">
        <button><FaMapMarkerAlt /> Current location</button>
      </div>
      <div className="date-picker">
        <button><FaCalendarAlt /> Choose date</button>
      </div>
      <div className="time-picker">
        <button><FaClock /> Choose time</button>
      </div>
      <div className="country-code1">
        <img src={im290} alt="Flag" className="flag" />

        <span>RO</span>
      </div>
    </header>
  );
};

export default Header;
