import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginComponrnt from '../AuthenticationComponent/LoginComponent'
import UserOtpAuthentication from '../AuthenticationComponent/UserOtpAuthentication';
import UserSignupComponent from '../AuthenticationComponent/UserSignupComponent';
import UserSignupAuthentication from '../AuthenticationComponent/UserSignupAuthentication';
import HomeComponent from '../UserPanelComponent/HomeComponent';
import UserLandingPageComponent from '../AuthenticationComponent/UserLandingPageComponent';


function AppRoutingModule() {
  return (
    <Router>
        <Routes>
        <Route path="/" element={<UserLandingPageComponent />} />
            <Route path="/LoginComponrnt" element={<LoginComponrnt />} />
            <Route path="/UserOtpAuthentication" element={<UserOtpAuthentication />} />
            <Route path="/UserSignupComponent" element={<UserSignupComponent />} />
            <Route path="/UserSignupAuthentication" element={<UserSignupAuthentication />} />
            <Route path="/HomeComponent" element={<HomeComponent />} />


        </Routes>
    </Router>
  )
}

export default AppRoutingModule