import React, { useState } from 'react';
import { FaSearch, FaMapMarkerAlt, FaCalendarAlt, FaClock, FaLanguage, FaRegUserCircle } from 'react-icons/fa';
import '../StyleComponents/UserHomeComponent.css';
import logo from '../AssetsComponents/header.png'
import im290 from '../AssetsComponents/im290.png';

function UserHomeComponent() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Categories with subcategories
  const categories = {
    Home: ['Plumber', 'Cleaning', 'Drainage', 'Electrician', 'Appliance repair'],
    Beauty: ['Salon', 'Spa', 'Makeup Artist'],
    Medical: ['Doctor', 'Pharmacy', 'Hospital'],
    Auto: ['Car Repair', 'Mechanic', 'Towing'],
    Pet: ['Pet Grooming', 'Veterinary', 'Pet Sitting'],
  };

  // Toggle the dropdown when the search bar is clicked
  const handleSearchClick = () => {
    setShowDropdown(prevState => !prevState);
  };

  // Handle category selection
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <header className="header5">
        <div className="logo1">
          <img src={logo} alt="Logo" />
        </div>
        <div className="search-bar1" onClick={handleSearchClick}>
          <FaSearch className="search-icon1" />
          <input type="text" placeholder="Search for service" />
        </div>
        {showDropdown && (
          <div className="dropdown">
            <div className="categories">
              <p style={{ fontWeight: 500, fontSize: '18px', color: 'black',marginBottom:'20px' }}>
                Choose from categories
              </p>
              {Object.keys(categories).map((category) => (
                <div
                  key={category}
                  className={`category-item ${selectedCategory === category ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                  <span>&gt;</span>
                </div>
              ))}
            </div>

            {selectedCategory && (
              <div className="subcategories">
                {categories[selectedCategory].map((sub) => (
                  <div key={sub} className="subcategory-item">
                    {sub}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="location1">
          <button><FaMapMarkerAlt /> Current location</button>
        </div>
        <div className="date-picker1">
          <button><FaCalendarAlt /> Choose date</button>
        </div>
        <div className="time-picker1">
          <button><FaClock /> Choose time</button>
        </div>
        <div className="country-code12">
          <img src={im290} alt="Flag" className="flag1" />
          <span>RO</span>
        </div>
        <div className="time-picker2">
          <button><FaRegUserCircle color="#1E88E5" /> Joseph </button>
        </div>
      </header>
    </>
  )
}

export default UserHomeComponent