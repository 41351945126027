import React from 'react'
import '../StyleComponents/UserLandingPageComponent.css'
import logo from '../AssetsComponents/header.png'
import { useNavigate } from 'react-router-dom';

import l1 from '../AssetsComponents/home.png'
import ro from '../AssetsComponents/romania.png'
import f1 from '../AssetsComponents/Frame1.png'
import f2 from '../AssetsComponents/Frame2.png'
import logo1 from '../AssetsComponents/logo1.png'
import rect1 from '../AssetsComponents/Rect1.png'
import aa1 from '../AssetsComponents/111.png'
import aa2 from '../AssetsComponents/222.png'
import aa3 from '../AssetsComponents/333.png'
import aa4 from '../AssetsComponents/444.png'
import UserFooter from '../ReusableComponents/UserFooter'





function UserLandingPageComponent() {
    const navigate = useNavigate();

    const handleLoginSignupClick = () => {
        navigate('/LoginComponrnt');
    };
    return (
        <>
            <div className="user-landing-page-container">
                <div className="user-landing-page-header">
                    <img src={logo} alt="Logo" className="center" />
                    <div className='header-text'>
                        <button>Find Services</button>
                        <button>
                            Find Service Providers
                        </button>
                        <button>Contact Us</button>
                    </div>
                    <div className="login-btn" onClick={handleLoginSignupClick}>
                        <button >Login/Signup</button>
                    </div>
                    <div className='drop-down'>
                        <img src={ro} alt='image' />
                        <span>RO</span>
                    </div>

                </div>
                <div className='bnr'>
                    <h1 style={{ color: 'white', fontWeight: 700, fontSize: '38px' }}>
                        Explore On-Demand Service's Now!
                    </h1>
                    <div className='card'>
                        <div className='box'>
                            <img src={l1} alt='logo' />
                            <span>Home</span>
                        </div>
                        <div className='box'>
                            <img src={l1} alt='logo' />
                            <span>Beauty</span>
                        </div>
                        <div className='box'>
                            <img src={l1} alt='logo' />

                            <span>Medical</span>
                        </div>
                        <div className='box'>
                            <img src={l1} alt='logo' />

                            <span>Auto</span>
                        </div>

                    </div>
                    <div className='card1'>
                        <div className='box'>
                            <img src={l1} alt='logo' />

                            <span>Home</span>
                        </div>
                        <div className='box'>
                            <img src={l1} alt='logo' />

                            <span>Beauty</span>
                        </div>
                        <div className='box'>
                            <img src={l1} alt='logo' />

                            <span>Medical</span>
                        </div>
                        <div className='box'>
                            <img src={l1} alt='logo' />

                            <span>Auto</span>
                        </div>

                    </div>
                </div>
                <div className="image-row">
                    <img src={f2} alt="Image 1" />
                    <img src={f1} alt="Image 2" />
                </div>
                <div className='container5'>
                    <div className='leftSection'>
                        <img
                            src={logo1}
                            alt="Cleaning service workers"
                            className='logo5'
                        />
                        <h1 className='heading'>
                            Are You Looking For An App To Research Local And International Services
                        </h1>
                        <button className='button'>Get In Touch →</button>
                    </div>
                    <div className="image-container">
                        {/* <div className="image-frame"></div> */}
                        <img
                            src={rect1}
                            alt="Cleaning service workers"
                            className="image"
                        />
                        <div className="green-box"></div> {/* Add this div for the green box */}
                    </div>
                </div>
                <p className='work'>How We Work</p>
                <div className="image-row-container">
                    <img src={aa1} alt="Image 1" />
                    <img src={aa2} alt="Image 2" />
                    <img src={aa3} alt="Image 3" />
                    <img src={aa4} alt="Image 4" />
                </div>
            </div>
            <UserFooter />

        </>

    )
}

export default UserLandingPageComponent